import * as React from "react"

import { Box } from "@vinomofo/components"
import ContentHeading from "../content-heading"
import axios from "axios"

const ResultsPage: React.FC<T> = ({ data, selections }) => {
  const [results, setResults] = React.useState<T>([])

  let varietals: any = {}
  Object.keys(selections).map((key) => {
    if (Object.keys(selections[key]).length > 0)
      varietals[key] = Object.keys(selections[key])
  })

  const fetch = async () => {
    const { data, status } = await axios.post(
      `/api/get-algolia-offers`,
      varietals
    )
    if (data) setResults(data)
  }
  React.useEffect(() => {
    fetch()
  }, [])

  console.log("results", results)

  return (
    <>
      <ContentHeading title={data.title} subtitle={data.subtitle} />
      <Box className={`content-body`} px={[60]}>
        <div className="results-page">
          <div className="offers-list">
            {results.map((offer, i) => (
              <div key={`offer-${i}`}>
                <img src={offer.cardImage.url} />
                <div className="title">{offer.name}</div>
              </div>
            ))}
          </div>
        </div>
      </Box>
    </>
  )
}

export default ResultsPage

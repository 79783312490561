import * as React from "react";

import { Box } from "@vinomofo/components"
import ContentHeading from "../content-heading"
import { GrLinkNext } from "react-icons/gr"
import { PreferencesButton } from "../button"
import * as Session from "lib-vinomofo/types/session"
import { useSession } from "lib-vinomofo/contexts/session-context"

import {
  cleanUp, getAnswers,
  getEmail, getRanks,
  getSpendings,
  writeEmail,
  writeFilters,
} from "../../utils/localstorage"
import { Link } from "gatsby"

const loadEmailValidity = (session: Session.Interstitial | any) => {
  return Session.isAuthenticated(session);
}

const EmailPage: React.FC<{data: any, selections: any}> = ({ data, selections }) => {
  const session = useSession();

  const [isValidEmail, setIsValidEmail] = React.useState<boolean>(loadEmailValidity(session));
  React.useEffect(() => {
    if(isValidEmail && Session.isAuthenticated(session)){
      writeEmail(session.currentUser.profile.email)
    }
  }, [session])

  React.useEffect(() => {
    generateFilters()
  }, [])

  const setEmail = (email: string) => {
    if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      setIsValidEmail(true)
      writeEmail(email)
    } else {
      setIsValidEmail(false)
      writeEmail("")
    }
  }

  const generateFilters = () => {
    const spending = getSpendings()
    let varietals: any = {}
    Object.keys(selections).map((key) => {
      if (Object.keys(selections[key]).length > 0) {
        const facetFilters = Object.keys(selections[key]).map((_item) => [
          `varietals:${_item}`,
        ])
        const numericFilters = spending[key] ? spending[key]
          .split("-")
          .map((price: string, i: number) => {
            return `unitPrice${i < 1 ? ">=" : "<="}${parseInt(
              price === 'Sky is the limit' ? '50' : price.replace(/[^0-9 ]/g, "")
            )}`
          }) : [`unitPrice>=10`]
        varietals[key] = {
          facetFilters,
          numericFilters,
        }
      }
    })
    writeFilters(varietals)
  }

  const sendDataToHubspot = () => {
    const email = getEmail();
    const spending = getSpendings();
    const ranks = getRanks();
    const answers = getAnswers();
    const save_money_mystery_deals = answers[1]?.answer === "yes" ? "yes" : "no";
    const small_quantity_but_iconic = answers[2]?.answer === "yes" ? "yes" : "no";
    const variety_of_mixed_packs = answers[0]?.answer === "yes" ? "yes" : "no";
    let preferred_wine_1 = "";
    let preferred_wine_2 = "";
    let preferred_wine_3 = "";
    let preferred_wine_4 = "";
    let preferred_wine_5 = "";
    let preferred_wine_6 = "";
    let preferred_wine_7 = "";
    let preferred_wine_8 = "";
    let preferred_wine_9 = "";
    let preferred_wine_10 = "";
    Object.keys(ranks).map((key, index) => {
      preferred_wine_1 = index === 0 ? ranks[key] : preferred_wine_1;
      preferred_wine_2 = index === 1 ? ranks[key] : preferred_wine_2;
      preferred_wine_3 = index === 2 ? ranks[key] : preferred_wine_3;
      preferred_wine_4 = index === 3 ? ranks[key] : preferred_wine_4;
      preferred_wine_5 = index === 4 ? ranks[key] : preferred_wine_5;
      preferred_wine_6 = index === 5 ? ranks[key] : preferred_wine_6;
      preferred_wine_7 = index === 6 ? ranks[key] : preferred_wine_7;
      preferred_wine_8 = index === 7 ? ranks[key] : preferred_wine_8;
      preferred_wine_9 = index === 8 ? ranks[key] : preferred_wine_9;
      preferred_wine_10 = index === 9 ? ranks[key] : preferred_wine_10;
    });
    const preferred_price_reds = spending.reds?.replaceAll("$", "").replaceAll("-", "_").replaceAll(" ","_");
    const preferred_price_whites_and_rose = spending.whites?.replaceAll("$", "").replaceAll("-", "_").replaceAll(" ","_");
    const preferred_price_sparkling = spending.sparkling?.replaceAll("$", "").replaceAll("-", "_").replaceAll(" ","_");
    const data = {
      email,
      preferences: {
        save_money_mystery_deals,
        small_quantity_but_iconic,
        variety_of_mixed_packs,
        preferred_wine_1,
        preferred_wine_2,
        preferred_wine_3,
        preferred_wine_4,
        preferred_wine_5,
        preferred_wine_6,
        preferred_wine_7,
        preferred_wine_8,
        preferred_wine_9,
        preferred_wine_10,
        preferred_price_reds,
        preferred_price_whites_and_rose,
        preferred_price_sparkling,
      }
    }

    fetch("/api/update-hubspot-preferences", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  React.useEffect(() => {
    generateFilters()
  }, [])

  const subtitle = Session.isAuthenticated(session) ? data.authenticatedSubtitle : data.subtitle;

  return (
    <>
      <ContentHeading title={data.title} subtitle={subtitle} />
      <Box className={`content-body`} px={[60]}>
        <div className="email-page">
          {!Session.isAuthenticated(session) && <input
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
            type="text"
            placeholder="Email"
          />}
          <br />
          <Link to={`/preferred-wines`}>
            <PreferencesButton
              flat
              size="md"
              variant="outline"
              hoverColor="white"
              hoverBg="#77b204"
              backgroundColor="#77b204"
              solid
              block
              disabled={!isValidEmail}
              // selected
              onClick={() => {
                sendDataToHubspot()
                cleanUp()
              }}
            >
              <GrLinkNext /> &nbsp; Show me the wine!
            </PreferencesButton>
          </Link>

          {!Session.isAuthenticated(session) && <div className="to-login">
            Already have an account? <a href="/login">SIGN IN</a>
          </div>}
        </div>
      </Box>
    </>
  )
}

export default EmailPage

import * as React from "react"
import { Box } from "@vinomofo/components"

const Intro: React.FC<{
  data: { title: string; subtitle: string }
  changePage: (direction: string) => void
}> = ({ data, changePage }) => {
  return (
    <>
      <Box className={`intro-body`}>
        <div className="intro-texts">
          <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
          <h5>{data.subtitle}</h5>
          <button
            onClick={() => {
              changePage("next")
            }}
            className="intro-btn"
          >
            Let's do this
          </button>
        </div>
      </Box>
    </>
  )
}

export default Intro

import * as React from "react"
import * as styles from "./page-progress.module.scss"

import { Box } from "@vinomofo/components"
import RangeBar from "../../components/range-bar";

const PageProgress: React.FC<T> = ({value, max, disabled}) => {
  return (
    <Box className={styles.pageProgress} p={0}>
      <RangeBar bubbleSupfix="%" value={value} max={max} disabled/>
    </Box>
  )
}

export default PageProgress

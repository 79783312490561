type StorageKeys =
  | "filters"
  | "selections"
  | "ranks"
  | "answers"
  | "spendings"
  | "email"
  | "page"

const write = (key: StorageKeys, data: any) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const read = (key: StorageKeys) => {
  const data = localStorage.getItem(key)
  return data ? JSON.parse(data) : null
}

export const remove = (key: StorageKeys) => {
  localStorage.removeItem(key)
}

export const writePage = (page: string) => {
  localStorage.setItem("page", page)
}
export const getPage = () => {
  return localStorage.getItem("page")
}

export const writeSelections = (data: any) => {
  write("selections", data)
}
export const getSelections = () => {
  return read("selections")
}

export const writeRanks = (data: any) => {
  write("ranks", data)
}
export const getRanks = () => {
  return read("ranks")
}

export const writeAnswers = (data: any) => {
  write("answers", data)
}
export const getAnswers = () => {
  return read("answers")
}

export const writeSpendings = (data: any) => {
  write("spendings", data)
}

export const getSpendings = () => {
  return read("spendings")
}

export const writeEmail = (data: any) => {
  write("email", data)
}

export const getEmail = () => {
  return read("email")
}

export const getEmailFromAuth0Token = () => {
  return read(`@@auth0spajs@@::${process.env.GATSBY_VINO_STOREFRONT_CLIENT_ID}::com.vinomofo:/api::openid profile email offline_access`)?.body.decodedToken?.user?.email
}

export const writeFilters = (data: any) => {
  write("filters", data)
}

export const cleanUp = () => {
  // remove("filters")
  remove("selections")
  // remove("ranks")
  remove("answers")
  remove("spendings")
  remove("email")
  remove("page")
}

import * as React from "react"

import { GrCheckmark, GrClose } from "react-icons/gr";
import { getAnswers, writeAnswers } from "../../utils/localstorage"

import { Box } from "@vinomofo/components"
import ContentHeading from "../content-heading"
import { PreferencesButton } from "../../components/button"

const Questionaire: React.FC<T> = ({ data }) => {
  const [answers, setAnswerData] = React.useState<T>({})
  const setAnswer = (answer: string, question: string, i: number) => {
    setAnswerData({ ...answers, [i]: { question, answer } })
  }

  React.useEffect(() => {
    const currentAnswers = getAnswers()
    writeAnswers(currentAnswers ? currentAnswers : {})
    setAnswerData(currentAnswers ? currentAnswers : {})
  }, [])

  React.useEffect(() => {
    writeAnswers(answers)
  }, [answers])
  return (
    <>
      <ContentHeading title={data.title} subtitle={data.subtitle} />
      <Box className={`content-body`}>
        <div className="questionaire">
          <div className="question-item">
            <div className="question-text blank"></div>
            <div className="label">Yes</div>
            <div className="label">No</div>
          </div>
          {data.questions.map((question: string, i: number) => (
            <div className="question-item">
              <div className="question-text">{question}</div>
              <PreferencesButton 
                flat
                size="md" 
                variant="outline" 
                hoverColor="white"
                hoverBg="#77b204"
                hoverBorder="#77b204"
                yesno={'yes'}
                selected={answers[i] && answers[i].answer === "yes"}
                onClick={() => setAnswer("yes", question, i)}
              >
                {answers[i] && answers[i].answer === "yes" &&(<GrCheckmark />)}
              </PreferencesButton>
              <PreferencesButton 
                flat
                size="md" 
                variant="outline" 
                hoverColor="white"
                hoverBg="#ff0000"
                hoverBorder="#ff0000"
                yesno={'no'}
                selected={answers[i] && answers[i].answer === "no"}
                onClick={() => setAnswer("no", question, i)}
              >
                {answers[i] && answers[i].answer === "no" &&(<GrClose />)}
              </PreferencesButton>
            </div>
          ))}
        </div>
      </Box>
    </>
  )
}

export default Questionaire

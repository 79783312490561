import * as styles from "./range-bar.module.scss"

import React, { useEffect, useRef, useState } from "react"

const RangeBar = ({
  disabled = false,
  min = 0,
  max = 100,
  step = 1,
  value,
  bubbleSupfix
}: {
  disabled?: boolean
  min?: string
  max?: string
  step?: string
  value?: string
  bubbleSupfix?: string
}) => {
  const rangeRef = useRef();
  const bubbleRef = useRef();

  const setBubble = (range, bubble) => {
    const val = Number(range.current.value);
    const min = range.current.min ? Number(range.current.min) : 0;
    const max = range.current.max ? Number(range.current.max) : 100;
    const newVal = Number(((val - min) * 100) / (max - min));

    bubble.current.innerHTML = bubbleSupfix ? `${val} ${bubbleSupfix}`: val;
    bubble.current.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
    range.current.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
  }

  const handler = (e) => {
    if (rangeRef.current && bubbleRef.current) setBubble(rangeRef, bubbleRef);
  };

  useEffect(() => {
    if (rangeRef.current && bubbleRef.current) setBubble(rangeRef, bubbleRef);
  }, [rangeRef, bubbleRef, value]);

  return (
    <div className={styles.rangeWrap}>
      <output className={styles.bubble} ref={bubbleRef} />
      <input 
        type="range" 
        ref={rangeRef} 
        className={styles.range} 
        min={min} 
        max={max} 
        step={step} 
        value={value}
        disabled
        onChange={(e) => handler(e)}
      />
    </div>
  );
};

export default RangeBar

import * as React from "react"

const ContentHeading = ({
  title,
  subtitle,
}: {
  title: string
  subtitle?: string
}) => {
  return (
    <div className="content-heading">
      <h2>{title}</h2>
      {subtitle && <p className="subheading">{subtitle}</p>}
    </div>
  )
}

export default ContentHeading

import * as React from "react"

import { Box } from "@vinomofo/components"
import ContentHeading from "../content-heading"
import { PreferencesButton } from "../button"

const VarietalsSelection: React.FC<T> = ({
  data,
  setSelection,
  selected
}) => {
  const varietal = data.varietal
  return (
    <>
      <ContentHeading title={data.title} subtitle={data.subtitle} />
      <Box className={`content-body`} px={[60]}>
        <ul className="opt-list">
          {data.options.map((item, i) => (
            <li
              key={`${varietal}-${i}`}
            >
              <PreferencesButton 
                flat
                solid
                block
                size="md" 
                variant="outline" 
                hoverColor="white"
                hoverBg="#77b204"
                selected={selected?.[item]}
                onClick={() => {
                  setSelection(varietal, item)
                }}
                backgroundColor="rgba(0,0,0,0.25)"
                borderColor="rgba(0,0,0,0.25)"
              >
                {item.indexOf("Open to something interesting") > 0
                  ? item.split(" - ")[1]
                  : item}
              </PreferencesButton>
            </li>
          ))}
        </ul>
      </Box>
    </>
  )
}

export default VarietalsSelection
